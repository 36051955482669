import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Content from '../components/content';

const NewsArticlePage = ({ pageContext, data }) => {
  return <Layout>
    <SEO title={pageContext.title} keywords={[]} />
    <Content {...data.contentfulNewsArticle} />
  </Layout>
}

NewsArticlePage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    contentfulNewsArticle: PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
}

export const pageQuery = graphql`
  query($id: String!) {
    contentfulNewsArticle(id: { eq: $id }) {
      id
      slug
      title
      date
      intro: excerpt {
        childMarkdownRemark {
          html
        }
      }
      image {
        id
        title
        fluid(maxWidth: 1440, toFormat: JPG, quality: 75) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default NewsArticlePage
